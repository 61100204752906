'use client'

// react core
import { forwardRef, useState } from 'react'

// styles
import { StyledImage } from './ImageItem.style'

// types
import { IImageItemProps } from './ImageItem'

export default forwardRef<HTMLDivElement | HTMLParagraphElement, IImageItemProps>(function ImageItem(
   { fallbackText, src, fallbackSrc, ...props },
   ref,
) {
   const [hasError, setHasError] = useState<boolean>(false)

   const handleError = () => {
      setHasError(true)
   }

   return (
      <>
         {hasError ? (
            (fallbackSrc as string)?.trim()?.length > 0 ? (
               <StyledImage
                  src={fallbackSrc as string}
                  ref={ref as React.Ref<HTMLImageElement>}
                  {...props}
                  data-isfallback={true}
               />
            ) : (fallbackText as string)?.trim()?.length > 0 ? (
               <p ref={ref as React.Ref<HTMLParagraphElement>}>{fallbackText}</p>
            ) : (
               <></>
            )
         ) : (
            <StyledImage
               src={src as string}
               ref={ref as React.Ref<HTMLImageElement>}
               {...props}
               onError={handleError}
               data-isfallback={false}
            />
         )}
      </>
   )
})
