'use client'

import { forwardRef } from 'react'
// types
import { TTypography } from './Typography'

// styles
import { StyledTypography } from './Typography.style'

export default forwardRef<HTMLDivElement, TTypography>(function Typography(
   { fontdefination, children, ...props },
   ref,
) {
   return (
      <StyledTypography fontdefination={fontdefination} ref={ref} {...props}>
         {children}
      </StyledTypography>
   )
})
