'use client'

// mui core
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

// fonts
import { MarkProWoff2, MarkProLightWoff2 } from '@/ThemeRegistry/theme'

// components
import Typography from '@/app/_component/atom/Typography'
import Button from '@/app/_component/atom/Button'
import Image from '@/app/_component/atom/ImageItem'

// types
import {
   TStyledContainerProps,
   StyledPrimaryHeadingProps,
   StyledTryAgainProps,
   StyledErrorContainerProps,
   StyledErrorHeadingProps,
   StyledBrokenApiImageProps,
   StyledErrorDescriptionProps,
   StyledRetryCtaProps,
   StyledErrorStatusProps,
   StyledErrorCodeProps,
} from './global-error.d'

export const StyledContainer = styled(Box)<TStyledContainerProps>(({}) => ({
   paddingTop: 80,
   paddingBottom: 0,
   minHeight: 'calc(100vh - 170px)',
   display: 'flex',
   flexDirection: 'column',
   alignContent: 'center',
   justifyContent: 'center',
}))

export const StyledDescription = styled(Typography)<StyledPrimaryHeadingProps>(({ theme }) => ({
   fontSize: 40,
   fontFamily: MarkProWoff2.style.fontFamily,
   fontWeight: 350,
   lineHeight: 'normal',
   textTransform: 'uppercase',
   background: theme.palette.ironGradientSubHeading,
   backgroundClip: 'text',
   WebkitBackgroundClip: 'text',
   WebkitTextFillColor: 'transparent',
   letterSpacing: 'normal',
   textAlign: 'center',
   marginBottom: 10,
}))

export const StyledTryAgainButton = styled(Button)<StyledTryAgainProps>(({}) => ({
   width: 150,
   margin: '0 auto',
   padding: '20px 10px',
   fontSize: 14,
   fontFamily: MarkProWoff2.style.fontFamily,
   fontWeight: 350,
}))

// error state
export const StyledErrorContainer = styled(Box)<StyledErrorContainerProps>(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   WebkitBoxAlign: 'center',
   alignItems: 'center',
   WebkitBoxPack: 'center',
   justifyContent: 'center',
   textAlign: 'center',
   position: 'relative',
   paddingTop: 95.88,
   paddingBottom: 101,
   margin: '0 auto',
   maxWidth: 615,
}))

export const StyledBrokenApiImage = styled(Image)<StyledBrokenApiImageProps>(({}) => ({
   marginBottom: 65,
}))

export const StyledErrorHeading = styled(Typography)<StyledErrorHeadingProps>(({ theme }) => ({
   fontFamily: MarkProLightWoff2.style.fontFamily,
   fontSize: 40,
   fontWeight: 350,
   lineHeight: '48px',
   letterSpacing: '0.1em',
   textAlign: 'center',
   textTransform: 'uppercase',
   marginBottom: 16,
}))

export const StyledErrorDescription = styled(Typography)<StyledErrorDescriptionProps>(({}) => ({
   fontSize: 24,
   fontWeight: 400,
   lineHeight: '36px',
   letterSpacing: '0.48px',
   textAlign: 'center',
   marginBottom: 39.83,
   opacity: 0.6,
}))

export const StyledRetryCta = styled(Button)<StyledRetryCtaProps>(({ theme }) => ({
   minWidth: 208,
   height: 47,
   fontSize: 14,
   fontWeight: 400,
   lineHeight: '15.4px',
   letterSpacing: '0.1em',
   textAlign: 'center',
   backgroundColor: theme.palette.common.white,
   color: theme.palette.charcoal,
   '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.charcoal,
   },
   marginBottom: 159.17,
}))

export const StyledErrorStatus = styled(Typography)<StyledErrorStatusProps>(({ theme }) => ({
   fontFamily: MarkProWoff2.style.fontFamily,
   fontSize: 16,
   fontWeight: 400,
   lineHeight: '17.6px',
   letterSpacing: '0.02em',
   textAlign: 'center',
   color: theme.palette.common.white,
   opacity: 0.6,
   marginBottom: 16,
   textTransform: 'uppercase',
}))

export const StyledErrorCode = styled(Typography)<StyledErrorCodeProps>(({ theme }) => ({
   fontFamily: MarkProWoff2.style.fontFamily,
   fontSize: 16,
   fontWeight: 400,
   lineHeight: '17.6px',
   letterSpacing: '0.02em',
   textAlign: 'center',
   color: theme.palette.common.white,
   opacity: 0.6,
   textTransform: 'uppercase',
}))
