// mui core
import { styled } from '@mui/material/styles'

// next core
import Image from 'next/image'

// types
import { IStyledImage, IStyledFallbackText } from './ImageItem'

export const StyledImage = styled(Image)<IStyledImage>(({}) => ({}))

export const StyledFallbackText = styled('p')<IStyledFallbackText>(({}) => ({
   margin: 0,
}))
