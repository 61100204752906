'use client'

// next core
import { useRouter } from 'next/navigation'

// styles
import {
   StyledErrorContainer,
   StyledErrorHeading,
   StyledBrokenApiImage,
   StyledErrorDescription,
   StyledRetryCta,
   StyledErrorStatus,
   StyledErrorCode,
} from './global-error.style'

// images
import BrokenApiErrorScenario from '@/public/images/broken_api_error_scenario.svg?url'

export default function GlobalError() {
   const router = useRouter()

   const onGoBackHandler = () => {
      router.back()
   }

   return (
      <StyledErrorContainer>
         <StyledBrokenApiImage
            src={BrokenApiErrorScenario}
            width={90.78}
            height={96}
            priority
            alt="Subscription plans could not be loaded."
         />
         <StyledErrorHeading component="h1">Oops! Something went wrong</StyledErrorHeading>
         <StyledErrorDescription fontdefination="smallRegular">
            There seems to be an error while loading the content. Please try again after sometime.
         </StyledErrorDescription>
         <StyledRetryCta onClick={onGoBackHandler}>Go Back</StyledRetryCta>

         <StyledErrorStatus>
            <span>Error Status Code:&nbsp;</span>
            <span>403</span>
         </StyledErrorStatus>

         <StyledErrorCode>
            <span>Error Code:&nbsp;</span>
            <span>Content_Unavailable</span>
         </StyledErrorCode>
      </StyledErrorContainer>
   )
}
