// mui core
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// fonts
import {
   MarkProBoldWoff2,
   MarkProBookWoff2,
   MarkProLightWoff2,
   MarkProMediumWoff2,
   MarkProRegularWoff2,
   MarkProThinWoff2,
   MarkProWoff2,
} from '../../../../ThemeRegistry/theme'

// types
import { TStyledTypography, TCustomStyles } from './Typography'

export const CustomStyles: TCustomStyles = {
   'extraLargeCommon': {
      fontFamily: MarkProLightWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '350',
      fontSize: '72px',
      lineHeight: '79px',
      letterSpacing: '0.1em',
   },
   'largeCommon': {
      fontFamily: MarkProLightWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '350',
      fontSize: '72px',
      lineHeight: '79px',
      letterSpacing: '0.1em',
   },
   'largeBody': {
      fontFamily: MarkProLightWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '350',
      fontSize: '48px',
      lineHeight: '53px',
      letterSpacing: '0.1em',
   },
   'mediumCommon': {
      fontFamily: MarkProLightWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: '48px',
      letterSpacing: '0.1em',
   },
   'mediumList': {
      fontFamily: MarkProMediumWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '450',
      fontSize: '32px',
      lineHeight: '48px',
   },
   'mediumBody': {
      fontFamily: MarkProBookWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '450',
      fontSize: '32px',
      lineHeight: '48px',
   },
   'smallRegular': {
      fontFamily: MarkProWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '26px',
      letterSpacing: '0.1em',
   },
   'smallMedium': {
      fontFamily: MarkProMediumWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '0.1em',
   },
   'smallBold': {
      fontFamily: MarkProBoldWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '26px',
      letterSpacing: '0.1em',
   },
   'smallBodyRegular': {
      fontFamily: MarkProWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '0.02em',
   },
   'smallBodyBold': {
      fontFamily: MarkProBoldWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
   },
   'extraSmallBody': {
      fontFamily: MarkProWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '22px',
      letterSpacing: '0.02em',
   },
   'extraSmallCap': {
      fontFamily: MarkProWoff2.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '22px',
      letterSpacing: '0.02em',
   },
}

export const StyledTypography = styled(Typography)<TStyledTypography>(({ theme, fontdefination }) => ({
   ...(fontdefination in CustomStyles ? CustomStyles[fontdefination as keyof TCustomStyles] : {}),
}))
